import React, { useEffect, useState } from 'react'
import {  useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import BasicModal from '../../../components/models/models';
import SavingOpportunity from '../../../components/opportunity/SavingOpportunity';

import Header from './components/header';
import PillCards from './components/pill-cards';
import UsageReportCards from './components/usage-report-cards';
import {
  getEmployeesRequest,
  getEmployeeDetailRequest,
  selectPersonalDashboard,
  getSavingOpportunity
} from '../../../redux/slices/personalDashboardSlice';
import { 
  getEmployeeData,
  getEmployeeSpecficData,
  selectCompanyDashboard,
}from '../../../redux/slices/companyDashboardSlice';

const EmployeeUsageReportModule = ({ path }) => {
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(false);
  const [employeeData, setEmployeeData] = useState();
  const [employeesData, setEmployeesData] = useState();
  const [ dashboard, setDashboard ] = useState()
  const personalState = useSelector(selectPersonalDashboard);
  const companyState = useSelector(selectCompanyDashboard);
  const [totalTeamCost, setTotalTeamCost] = useState(null);
  const [averageTeamCost, setAverageTeamCost] = useState(null);
  // const { employeesData} = companyState;

  const { savingOpportunityData } = personalState || {}
  const dispatch = useDispatch();
  const { id ,user_id } = useParams();
  
  useEffect(() => {
    if (path.includes('Teams')) {
      dispatch(getEmployeeDetailRequest(user_id));
    } else {
      dispatch(getEmployeeSpecficData(id));
    }
  }, [dispatch, path, id, user_id]);

  // useEffect(() => {
  //   if (path.includes('Teams')){
  //     dispatch(getEmployeesRequest(user_id));
  //   } else {
  //     dispatch(getEmployeeData(id));
  //   }
  // }, [dispatch, path, id,user_id]);

  useEffect(() => {
    if (path.includes('Teams')) {
      setEmployeesData(personalState.employeesData);
    } else {
      setEmployeesData(companyState.employeesData);
    }
  }, [path, personalState.employeesData, companyState.employeesData]);

  useEffect(() => {
    if (path.includes('Teams')) {
      setEmployeeData(personalState.employeeDetailData);
    } else {
      setEmployeeData(companyState.employeeData);
    }
  }, [path, personalState.employeeDetailData, companyState.employeeData]);

  useEffect(() => {
    if (employeesData) {
      const totalCost = employeesData?.reduce((sum, employee) => {
        return sum + (employee.monthly_cost || 0);
      }, 0);
      setTotalTeamCost(totalCost);
      const presentEmployees = employeesData?.length;
      const avgEmployeeCost = presentEmployees > 0 ? totalCost / presentEmployees : 0;
      const formattedAvgEmployeeCost = avgEmployeeCost.toFixed(2);
      setAverageTeamCost(formattedAvgEmployeeCost);
    }
  }, [employeesData]);

  useEffect(()=>{
    if (path.includes('Teams')) {
      dispatch(getSavingOpportunity(user_id,"emp_id"))
    }else{
      dispatch(getSavingOpportunity(id,"emp_id"))
    }
  },[])
  
  return (
    <div className='right_panel relative flex flex-col max-w-[100%] px-[20px] lg:px-[30px] pt-[16px]'>
      <Header data={employeeData} path={path}/>
      <div className="card-section">
        { savingOpportunityData?.savings_opportunities?.length > 0 &&  <SavingOpportunity opportunity={savingOpportunityData} userId={id}  /> }
        <div className='cards_wrap flex flex-col gap-[48px] h-[100vh] lg:h-[680px] pt-[32px] pb-[20px] overflow-y-scroll scrollbar-hide'>
          <PillCards  totalTeamCost={totalTeamCost} averageTeamCost={averageTeamCost} data={employeeData} setState={setState} setPopup={setPopup} id={id} setDashboard={setDashboard} />
          <UsageReportCards data={employeeData}/>
        </div>
      </div>

      {popup && <BasicModal popup={popup} state={state} setState={setState} setPopup={setPopup} empId={id ?? user_id} dashboard={dashboard && dashboard} />}
    </div>
  )
};

export default EmployeeUsageReportModule;

